.header {
  margin-top: 20px;
  margin-bottom: 15px;

  &-line {
    height: 3px;
    background-color: #F1F2F7;
    width: 100%;
  }
}

.logo {
  font-size: 22px;
  font-weight: $bold;
}