//Fonts
$main-font: 'Inter', sans-serif;

//Font-weight
$regular: 400;
$bold: 700;

//Colors
$primary: #8AC7EA;
$black: #0F0E0E;
$lightGray: #F3F4F5;
$white: #FDFDFD;