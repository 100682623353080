@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.title-hero {
  font-size: 45px;
  font-weight: $bold;

  @include respond(L) {
    font-size: 35px;
  }

  @include respond(M) {
    font-size: 30px;
  }

  @include respond(XS) {
    font-size: 25px;
  }
}

.title-services {
  font-size: 35px;
  font-weight: $bold;

  @include respond(L) {
    font-size: 30px;
  }

  @include respond(M) {
    font-size: 25px;
  }

}

.heading {
  font-size: 22px;
  font-weight: $bold;
}

.content {
  font-size: 15px;

  @include respond(L) {
    font-size: 14px;
  }

  @include respond(M) {
    font-size: 13px;
  }
}
