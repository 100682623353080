.footer {
  background-color: $black;
  padding: 50px 0;

&-content {
  font-size: 13px;
  text-align: center;
  color: $white;

}
}