.button {
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:  0.2s;


  &-primary {
    background-color: $primary;
    width: 280px;
    height: 64px;
    color: $white;

    @include respond(XXS) {
      width: 300px;
    }

    &:hover {
    border: 1px solid $primary;
    background-color: $white;
    color: $black;
    }
  }

  &-outline {
    border: 1px solid $primary;
    background-color: $white;
    width: 138px;
    height: 33px;
    color: $black;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &-text {
    margin-bottom: 0;
    font-weight: $bold;

    &-primary {
      font-size: 18px;
    }

    &-outline {
      font-size: 12px; 
    }
  }
}