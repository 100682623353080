.nav {
  padding: 20px 0;

  &-item {
    margin-right: 10px;

    @include respond(M) {
      margin-right: 0;
    }

    .highlight {
      height: 2px;
      background-color: transparent;
    }

    &:hover .highlight {
      height: 2px;
      background-color: $primary;
    }
  }

  &-link {
    color: $black;

    &:hover {
      cursor: pointer;
      color: $black;
    }
  }

  &-text {
    font-size: 16px;

    @include respond(M) {
      font-size: 14px;
    }
  }

  &-line {
    height: 3px;
    width: 100%;
    background-color: #f1f2f7;
  }
}
