.cookie {
  &-openButton {
    display: flex;
    justify-content: center;
  }

  &-modal-header {
    border-bottom: none;
    padding: 20px 20px 0 20px;
  }

  &-modal-dialog {
    max-width: 60%;

    @include respond(M) {
      max-width: 80%;
    }
    @include respond(S) {
      max-width: 100%;
    }
  }

  &-btn-close {
    background-color: transparent;
    border: none;
  }

  &-modal-body {
    padding: 0 70px 50px 70px;

    @include respond(M) {
    padding: 0 50px;

    }
    @include respond(S) {
    padding: 0 20px;

    }
  }
}

.modal-backdrop {
  z-index: -1;
  background-color: transparent;
}

.overlay-dark {
  background-color: rgba(0,0,0,0.7);
}