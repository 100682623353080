.services {
  background-color: $lightGray;
  padding-bottom: 100px;

  @include respond(M) {
    padding-bottom: 50px;
  }
}

.card {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px 30px 0 30px;
  border-color: #fff;

  @include respond(L) {
    padding: 20px 20px 10px 20px;
  }

  &-image {
    height: 70%;

    @include respond(M) {
      height: 50%;
    }

    &-container {
      overflow: hidden;
      width: 100%;
      height: 270px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(L) {
        height: 250px;
      }
    }
  }

  &-heading-wrapper {
    min-height: 60px;
    @include respond(L) {
      min-height: 70px;
    }
  }

  &-content-wrapper {
    min-height: 120px;
    // @include respond(L) {
    //   min-height: 140px;
    // }
  }
}
