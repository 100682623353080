.hero {
  margin-top: 70px;
  margin-bottom: 110px;

  @include respond(S) {
    margin-top: 40px;
    margin-bottom: 70px;
  }

  &-image {
    width: 100%;
    height: 100%;

    &-container {
      width: 60%;
    }
  }
}
