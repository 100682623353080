p {
  margin-bottom: 0;
}

.mb-60px {
  margin-bottom: 60px;
}

.mb-80px {
  margin-bottom: 80px;
}

.mb-100px {
  margin-bottom: 100px;
}

.mt-100px {
  margin-top: 100px;
}

.mt-60px {
  margin-top: 60px;
}

.mt-80px {
  margin-top: 80px;
}

.upp {
  text-transform: uppercase;
}

.color-primary {
  color: $primary;
}

.linkNoStyle {
  text-decoration: none;
}
